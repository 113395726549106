import styled from 'styled-components';

import theme from '../../utils/theme';

import { IPanelProps } from './types';

const getBackgroundColor = (variant: string) => {
  switch (variant) {
    case 'pink':
      return theme.colors.pink;
    case 'white':
      return theme.colors.white;
    case 'offWhite':
      return theme.colors.offWhite;
    case 'blueChalk':
      return theme.colors.blueChalk;
    default:
      return 'transparent';
  }
};

export const PanelWrapper = styled.div<IPanelProps>(
  ({ variant, hasPadding, hasBorder }) => ({
    backgroundColor: getBackgroundColor(variant || ''),
    borderRadius: '25px',
    borderWidth: hasBorder ? 1 : 0,
    borderStyle: 'solid',
    borderColor: theme.colors.lightGrey,
    overflow: 'hidden',
    position: 'relative',
    padding: hasPadding ? '20px' : 0,
    zIndex: 1,
  }),
);
