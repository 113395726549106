import Link from 'next/link';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { CarouselRow } from 'components/layout/Carousel';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { Heading2 } from 'components/text';
import TitleLink from 'components/TitleLink';
import { ITitleLink } from 'components/TitleLink/types';
import TitleRow from 'components/TitleRow';
import { mobile } from 'utils/media';

import ProductCarouselItem from './product-carousel-item';
import { HeaderContainer, TabsRow, TabLink } from './styles';

export interface ProductCarouselTab {
  _key: string;
  title: ReactNode;
  titleLink?: ITitleLink;
  products?: Sproutl.ProductListing[];
}

export interface ProductCarouselProps {
  tabs?: ProductCarouselTab[];
  extendedWidth?: boolean;
}

interface StyledHeadingProps {
  hasTabs: boolean;
}

const StyledHeading = styled(Heading2)<StyledHeadingProps>((props) =>
  props.hasTabs
    ? {
        [mobile]: {
          fontSize: 32,
        },
      }
    : {},
);

export default function ProductCarousel({
  tabs = [],
  extendedWidth = true,
}: ProductCarouselProps) {
  const [selectedTab, selectTab] = useState(0);

  if (!tabs.length) {
    return null;
  }

  const { text, url } = tabs[selectedTab]?.titleLink || {};

  return (
    <Section bg="normal">
      <Container noPadding noGutter={extendedWidth}>
        <HeaderContainer>
          <TitleRow>
            <StyledHeading hasTabs={tabs.length > 1} inline>
              {tabs[selectedTab].title}
            </StyledHeading>
            {text && url && (
              <Link href={url} passHref>
                <TitleLink href={url}>{text}</TitleLink>
              </Link>
            )}
          </TitleRow>
          {tabs.length > 1 && (
            <TabsRow>
              {tabs.map((tab, index) => (
                <TabLink
                  key={index}
                  onClick={() => selectTab(index)}
                  active={index === selectedTab}
                >
                  {tab.title}
                </TabLink>
              ))}
            </TabsRow>
          )}
        </HeaderContainer>
        <CarouselRow>
          {tabs[selectedTab].products?.map((product) => {
            if (!product || !product.sku) {
              return null;
            }
            return <ProductCarouselItem product={product} key={product.sku} />;
          })}
        </CarouselRow>
      </Container>
    </Section>
  );
}
