import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useState, useCallback } from 'react';

import { AddToNotificationProduct } from 'components/product/AddToNotification/types';
import { addLineItems } from 'utils/commercetools/cart';
import { trackAddToBasket } from 'utils/gtm/events';

interface AddedInterface {
  updatedAt: Date;
  items: AddToNotificationProduct[];
}

export function useProductSetAddToBag() {
  const router = useRouter();

  const [addedToBag, setAddedToBag] = useState<AddedInterface | null>(null);
  const [addToBagError, setAddToBagError] = useState<string | null>(null);
  const [isAddingToBag, setIsAddingToBag] = useState<boolean>(false);

  const addToBag = useCallback(
    async (products: Sproutl.ProductListing[]) => {
      const lineItems = products
        .map((product) => ({
          name: product.name,
          sku: product.sku,
          channel: product.channel,
          price: product.price as Sproutl.Money,
          quantity: 1,
        }))
        .filter(({ price }) => price);

      try {
        await addLineItems(lineItems);

        trackAddToBasket(lineItems);

        const itemsAddedToBag = products
          .map((product) => ({
            title: product.name,
            src: product.photo,
            price: product.price as Sproutl.Money,
            quantity: 1,
          }))
          .filter(({ price }) => price);

        setAddedToBag({
          updatedAt: new Date(),
          items: itemsAddedToBag,
        });
      } catch (e: any) {
        setAddToBagError(e.message);
        Sentry.captureException(e);
      }

      setIsAddingToBag(false);
      router.prefetch('/basket');
      router.prefetch('/checkout');
    },
    [router],
  );

  return {
    addedToBag,
    addToBagError,
    isAddingToBag,
    addToBag,
  };
}
