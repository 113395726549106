import Link from 'next/link';
import React from 'react';

import Image from 'components/Image';
import Container from 'components/layout/Container';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import Section from 'components/layout/Section';
import SiteLink from 'components/Link';
import { Heading2 } from 'components/text';
import TitleLink from 'components/TitleLink';
import TitleRow from 'components/TitleRow';
import { mobile, tablet, desktop } from 'utils/media';

import {
  CategoryLabel,
  Grid,
  ArticleEl,
  ArticleImage,
  ArticleLabel,
  ArticleLede,
  ArticleInfo,
} from './styles';
import { ArticleProps, ArticleGridProps } from './types';

function Article({ title, image, category, slug, size, lede }: ArticleProps) {
  return (
    <ArticleEl size={size} key={title}>
      <SiteLink href={`/articles/${slug?.current}`} title={title}>
        <ArticleImage>
          <ResponsiveBox rounded aspectRatio={0.75}>
            <Image
              src={image}
              aspectRatio={0.75}
              alt=""
              sizes={`${mobile} 150px, ${tablet} 25vw, ${desktop} 290px`}
            />
          </ResponsiveBox>
        </ArticleImage>
        <ArticleInfo>
          <CategoryLabel>{category?.name}</CategoryLabel>
          <ArticleLabel as="h3">{title}</ArticleLabel>
          {size === 'medium' ? <ArticleLede>{lede}</ArticleLede> : null}
        </ArticleInfo>
      </SiteLink>
    </ArticleEl>
  );
}

export default function ArticleGrid({
  title,
  size,
  articles = [],
  titleLink = {},
}: ArticleGridProps): React.ReactElement {
  const { text, url } = titleLink;
  return (
    <Section bg={size === 'large' ? 'light' : null}>
      <Container noPadding noGutter>
        <TitleRow>
          <Heading2 inline>{title}</Heading2>
          {text && url && (
            <Link href={url} passHref>
              <TitleLink>{text}</TitleLink>
            </Link>
          )}
        </TitleRow>
        <Grid>
          {articles?.map((article, index) => (
            <Article key={index} size={size} {...article} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
}
