import React, { ReactNode } from 'react';

import { BillboardProps } from 'components/blocks/Billboard/types';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import ProductsSet from 'components/ProductsSet';
import { IProductsSetProps } from 'components/ProductsSet/types';
import SocialCarousel from 'components/SocialCarousel';
import { SocialCarouselProps } from 'components/SocialCarousel/types';
import VideoPlayer from 'components/VideoPlayer';
import { VideoPlayerProps } from 'components/VideoPlayer/types';

import ArticleGrid from '../ArticleGrid';
import { ArticleProps, ArticleGridProps } from '../ArticleGrid/types';
import Billboard from '../Billboard';
import ContentCarousel, { IContentCarouselProps } from '../ContentCarousel';
import CopyTree from '../CopyTree';
import { CopyTreeProps } from '../CopyTree/types';
import EmailSubscribeForm from '../EmailSubscribeForm';
import { IEmailSubscribeFormProps } from '../EmailSubscribeForm/types';
import Hero, { HeroProps } from '../Hero';
import MultiLayerBillboard from '../MultiLayerBillboard';
import { MultiLayerBillboardProps } from '../MultiLayerBillboard/types';
import ProductCarousel, {
  ProductCarouselProps,
  ProductCarouselTab,
} from '../ProductCarousel';
import ResponsiveImageBlock from '../ResponsiveImageBlock';
import { ResponsiveImageBlockProps } from '../ResponsiveImageBlock/types';
import ShortcutGrid, {
  ShortcutGridProps,
  ShortcutProps,
} from '../ShortcutGrid';
import SimpleTitleAndTextBlock from '../SimpleTitleAndTextBlock';
import { SimpleTitleAndTextProps } from '../SimpleTitleAndTextBlock/types';
import SocialBlock from '../SocialBlock';
import { SocialBlockProps } from '../SocialBlock/types';
import Text from '../Text';
import { TextProps } from '../Text/types';

interface SanityHero extends HeroProps {
  _key: string;
  _type: 'hero';
}

interface SanityBillboard extends BillboardProps {
  _key: string;
  _type: 'billboard';
}

interface SanityMultiLayerBillboard extends MultiLayerBillboardProps {
  _key: string;
  _type: 'multiLayerBillboard';
}

interface SanityShortcut extends ShortcutProps {
  _key: string;
  _type: 'shortcut';
}

interface SanityShortcutGrid extends ShortcutGridProps {
  _key: string;
  _type: 'shortcutGrid';
  shortcuts?: SanityShortcut[];
}

interface SanityArticle extends ArticleProps {
  _key: string;
  _type: 'article';
}

interface SanityArticleGrid extends ArticleGridProps {
  _key: string;
  _type: 'articleGrid';
  articles?: SanityArticle[];
}

export interface SanityProductCarouselTab extends ProductCarouselTab {
  _key: string;
  _type: 'tab';
}

interface SanityProductCarousel extends ProductCarouselProps {
  _key: string;
  _type: 'productCarousel';
  tabs?: SanityProductCarouselTab[];
}

interface SanityContentCarousel extends IContentCarouselProps {
  _key: string;
  _type: 'contentCarousel';
}

interface SanityTextBlock extends TextProps {
  _key: string;
  _type: 'textBlock';
}

interface SanityCopyTree extends CopyTreeProps {
  _key: string;
  _type: 'copyTree';
}

interface SanityVideoPlayer extends VideoPlayerProps {
  _key: string;
  _type: 'videoPlayer';
  container?: Sanity.Container;
}

interface SanitySocialBlock extends SocialBlockProps {
  _key: string;
  _type: 'socialBlock';
}

interface SanitySimpleTitleAndTextProps extends SimpleTitleAndTextProps {
  _key: string;
  _type: 'simpleTitleAndCopy';
}

interface SanityResponsiveImageBlockProps extends ResponsiveImageBlockProps {
  _key: string;
  _type: 'responsiveImage';
}

interface SanitySocialCarousel extends SocialCarouselProps {
  _key: 'string';
  _type: 'socialCarousel';
}

interface SanityEditSet extends IProductsSetProps {
  _key: 'string';
  _type: 'editSet';
}

interface SanityEmailSubscribeForm extends IEmailSubscribeFormProps {
  _key: 'string';
  _type: 'emailSubscribeForm';
}

export type SanityBlock =
  | SanityHero
  | SanityBillboard
  | SanityMultiLayerBillboard
  | SanityArticleGrid
  | SanityShortcutGrid
  | SanityProductCarousel
  | SanityContentCarousel
  | SanityTextBlock
  | SanityVideoPlayer
  | SanityCopyTree
  | SanitySocialBlock
  | SanitySimpleTitleAndTextProps
  | SanityResponsiveImageBlockProps
  | SanitySocialCarousel
  | SanityEditSet
  | SanityEmailSubscribeForm;

interface SanityContainerProps {
  container?: Sanity.Container;
  noPadding?: boolean;
  children?: ReactNode;
}

interface MappedBlockProps {
  block: SanityBlock;
  extendedWidth?: boolean;
  index: number;
}

function SanityContainer({
  children,
  container = 'normal',
  noPadding = false,
}: SanityContainerProps) {
  return (
    <Container
      inset={container === 'inset'}
      noGutter={container === 'fullWidth'}
      noPadding={noPadding}
    >
      {children}
    </Container>
  );
}

const MappedBlock = ({
  block,
  extendedWidth = true,
  index,
}: MappedBlockProps) => {
  const { _type, _key, ...blockProps } = block;

  switch (_type) {
    case 'hero':
      return <Hero {...(blockProps as SanityHero)} key={_key} />;
    case 'billboard':
      return (
        <Billboard
          {...(blockProps as SanityBillboard)}
          index={index}
          key={_key}
        />
      );
    case 'multiLayerBillboard':
      return (
        <MultiLayerBillboard
          {...(blockProps as SanityMultiLayerBillboard)}
          index={index}
          key={_key}
        />
      );
    case 'articleGrid':
      return <ArticleGrid {...blockProps} key={_key} />;
    case 'shortcutGrid':
      return <ShortcutGrid {...blockProps} key={_key} />;
    case 'productCarousel':
      return (
        <ProductCarousel
          {...blockProps}
          key={_key}
          extendedWidth={extendedWidth}
        />
      );
    case 'contentCarousel':
      return (
        <ContentCarousel
          {...(blockProps as SanityContentCarousel)}
          key={_key}
        />
      );
    case 'videoPlayer':
      return (
        <Section bg="light" padding="none" key={_key}>
          <SanityContainer
            container={(blockProps as SanityVideoPlayer).container}
            noPadding
          >
            <VideoPlayer
              video={(blockProps as SanityVideoPlayer).video}
              autoplay={(blockProps as SanityVideoPlayer).autoplay}
            />
          </SanityContainer>
        </Section>
      );
    case 'textBlock':
      return (
        <Section
          padding="small"
          bg={(blockProps as SanityTextBlock).bg || 'light'}
          key={_key}
        >
          <Text body={(blockProps as SanityTextBlock).body} />
        </Section>
      );
    case 'copyTree':
      return <CopyTree {...(blockProps as SanityCopyTree)} key={_key} />;
    case 'socialBlock':
      return <SocialBlock {...(blockProps as SanitySocialBlock)} key={_key} />;
    case 'simpleTitleAndCopy':
      return (
        <SimpleTitleAndTextBlock
          {...(blockProps as SanitySimpleTitleAndTextProps)}
          key={_key}
        />
      );
    case 'responsiveImage':
      return (
        <ResponsiveImageBlock
          {...(blockProps as SanityResponsiveImageBlockProps)}
          key={_key}
        />
      );
    case 'socialCarousel':
      return (
        <SocialCarousel {...(blockProps as SocialCarouselProps)} key={_key} />
      );
    case 'editSet':
      return (
        <Container key={_key}>
          <ProductsSet {...(blockProps as IProductsSetProps)} />
        </Container>
      );
    case 'emailSubscribeForm':
      return (
        <EmailSubscribeForm
          {...(blockProps as IEmailSubscribeFormProps)}
          key={_key}
        />
      );
    default:
  }

  return null;
};

interface RenderBlocksProps {
  blocks: SanityBlock[];
  extendedWidth?: boolean;
}

export default function RenderBlocks({
  blocks,
  extendedWidth,
}: RenderBlocksProps) {
  return (
    <>
      {blocks.map((block, index) =>
        MappedBlock({ block, extendedWidth, index }),
      )}
    </>
  );
}
