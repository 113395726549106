import React, { FC, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ImageComponent from 'components/Image';
import SiteLink from 'components/Link';
import { swapPhoto, mousePosition } from 'components/ProductItem/helpers';
import { getProductUrl } from 'utils/commercetools/product';
import { mobile } from 'utils/media';

import {
  StyledProductCarouselItem,
  StyledResponsiveBox,
  Label,
} from './styles';
import { IProductCarouselItemProps } from './types';

export const ProductCarouselItem: FC<IProductCarouselItemProps> = ({
  product,
  key,
}) => {
  const { name, photos } = product;

  const [displayedPhoto, setDisplayedPhoto] =
    useState<Sproutl.CloudinaryImageAsset | null>(photos?.[0] ?? null);

  const hasPreloadedSecondImage = useRef<boolean>(false);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    // Preload the second image on entrance to the viewport
    // in the same fashion as webP to prevent flicker in loading the image on hover.
    if (photos?.[1] && !hasPreloadedSecondImage.current) {
      new Image().src = photos[1].secure_url;
      hasPreloadedSecondImage.current = true;
    }
  }, [photos, inView, hasPreloadedSecondImage]);

  return (
    <StyledProductCarouselItem
      size="large"
      key={key}
      data-testid="productCarouselItem"
      ref={ref}
      onMouseEnter={() =>
        swapPhoto({ photos, mousePos: mousePosition.In, setDisplayedPhoto })
      }
      onMouseLeave={() =>
        swapPhoto({ photos, mousePos: mousePosition.Out, setDisplayedPhoto })
      }
    >
      <SiteLink href={getProductUrl(product)} title={name}>
        <StyledResponsiveBox aspectRatio={1}>
          {displayedPhoto ? (
            <ImageComponent
              loader="cloudinary"
              src={displayedPhoto}
              aspectRatio={1}
              sizes={`${mobile} 150px, 290px`}
              alt={name}
            />
          ) : null}
        </StyledResponsiveBox>
        <Label>
          <div>{name}</div>
        </Label>
      </SiteLink>
    </StyledProductCarouselItem>
  );
};

export default ProductCarouselItem;
