import Head from 'next/head';
import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Frame, VideoElement } from './styles';
import { BackgroundVideoProps, BackgroundVideoSource } from './types';

const pickSource = (sources: BackgroundVideoSource[]) => {
  let newSource = null;

  if (typeof window !== 'undefined') {
    sources.forEach((source) => {
      if (window.matchMedia(source.media).matches) {
        newSource = source;
      }
    });
  }

  return newSource;
};

export default function BackgroundVideo({
  video,
  breakpoints,
  preloadPoster = false,
}: BackgroundVideoProps) {
  const urlEncodedId = encodeURI(video.public_id);

  const posterSrc = `https://res.cloudinary.com/sproutl/video/upload/c_fill,h_540,so_0,w_960/v1/${urlEncodedId}.webp`;

  const sources = useMemo(
    () =>
      Object.entries(breakpoints).map(([media, dimensions]) => ({
        formats: [
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_webm,w_${dimensions.width},h_${dimensions.height},c_fill/${urlEncodedId}.webm`,
            type: 'video/webm',
          },
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h265,w_${dimensions.width},h_${dimensions.height},c_fill/${urlEncodedId}.mp4`,
            type: 'video/mp4; codecs=hvc1',
          },
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h264,w_${dimensions.width},h_${dimensions.height},c_fill/${urlEncodedId}.mp4`,
            type: 'video/mp4',
          },
        ],
        media: media.replace('@media', ''),
      })),
    [breakpoints, urlEncodedId],
  );

  const [currentSource, setCurrentSource] =
    useState<BackgroundVideoSource | null>(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const newSource = pickSource(sources);

    if (newSource) {
      setCurrentSource(newSource);
    }
  }, [sources]);

  const poster = preloadPoster ? posterSrc : inView ? posterSrc : undefined;

  return (
    <>
      {preloadPoster ? (
        <Head>
          <link rel="preload" href={posterSrc} as="image" />
        </Head>
      ) : null}
      <Frame>
        <VideoElement autoPlay muted playsInline loop poster={poster} ref={ref}>
          {(inView || preloadPoster) && currentSource
            ? currentSource.formats.map((format) => (
                <source key={format.src} src={format.src} type={format.type} />
              ))
            : null}
        </VideoElement>
      </Frame>
    </>
  );
}
