import React from 'react';

import BackgroundVideo from 'components/BackgroundVideo';
import Button from 'components/Button';
import Image from 'components/Image';
import Link from 'components/Link';
import { tablet, mobile, desktop } from 'utils/media';

import {
  FixedHeader,
  StyledHeading,
  StyledResponsiveBox,
  HeadingWrapper,
  StyledSubtitle,
  StyledDeepLink,
  ButtonWrapper,
  Underlay,
} from './styles';

export interface HeroProps {
  title?: string;
  subTitle?: string;
  banner?: any;
  darkenOverlay?: boolean;
  video?: Sanity.CloudinaryVideo;
  colorScheme?: {
    text?: Sproutl.ColorScheme;
    subTitle?: Sproutl.ColorScheme;
    button?: Sproutl.ButtonScheme;
  };
  cta?: {
    title?: string;
    url?: string;
  };
}

function Hero({
  colorScheme = {
    text: 'black',
    subTitle: 'black',
    button: 'primary',
  },
  title,
  banner,
  subTitle,
  cta,
  darkenOverlay,
  video,
}: HeroProps) {
  const Wrapper = banner || video ? StyledResponsiveBox : FixedHeader;

  return (
    <Wrapper aspectRatio={{ [mobile]: 9 / 10, [tablet]: 1 / 3 }}>
      <HeadingWrapper>
        <StyledHeading colorScheme={colorScheme.text || 'black'} inline>
          {title}
        </StyledHeading>
        {subTitle && (
          <StyledSubtitle colorScheme={colorScheme.subTitle || 'black'}>
            <p>{subTitle}</p>
            <StyledDeepLink href="#plpDescription">Read more</StyledDeepLink>
          </StyledSubtitle>
        )}
        {cta && cta.title && cta.url && (
          <ButtonWrapper>
            <Button
              as="span"
              variant={
                colorScheme.button === 'primary' ? 'primary' : 'secondary'
              }
              colorScheme={
                colorScheme.button !== 'primary'
                  ? colorScheme.button
                  : undefined
              }
            >
              {cta.title}
            </Button>
          </ButtonWrapper>
        )}
      </HeadingWrapper>
      {video ? (
        <BackgroundVideo
          video={video}
          breakpoints={{
            [mobile]: { width: 750, height: 682 },
            [tablet]: { width: 1024, height: 345 },
            [desktop]: { width: 1920, height: 644 },
          }}
          preloadPoster
        />
      ) : banner ? (
        <Image
          src={banner}
          aspectRatio={{ [mobile]: 9 / 10, [tablet]: 1 / 3 }}
          alt=""
          sizes="100vw"
          loading="eager"
        />
      ) : null}
      {(banner || video) && darkenOverlay ? <Underlay /> : null}
    </Wrapper>
  );
}

export default function HeroWrapper(heroProps: HeroProps) {
  const { cta } = heroProps;

  return cta && cta.title && cta.url ? (
    <Link href={cta.url}>
      <Hero {...heroProps} />
    </Link>
  ) : (
    <Hero {...heroProps} />
  );
}
