import { Formik, Field, Form } from 'formik';
import Link from 'next/link';
import React, { useState } from 'react';

import Button from 'components/Button';
import Input from 'components/forms/Input';
import Image from 'components/Image';
import Section from 'components/layout/Section';
import SiteLink from 'components/Link';
import { VisuallyHidden } from 'components/text';
import { mobile, tablet } from 'utils/media';
import subscribeEmail from 'utils/newsletter/subscribe';
import { PortableText } from 'utils/sanity';

import {
  ButtonContainer,
  Container,
  PanelContent,
  Fields,
  StyledHeading4,
  ImageWrapper,
  StyledPanel,
} from './styles';
import { IEmailSubscribeFormProps } from './types';

export default function EmailSubscribeForm({
  title,
  body,
  backgroundImage,
}: IEmailSubscribeFormProps) {
  const [isFinished, setIsFinished] = useState(false);

  return (
    <Formik
      onSubmit={async (values) => {
        try {
          await subscribeEmail(values);
          setIsFinished(true);
        } catch (error) {}
        // @TODO: Error handling
      }}
      initialValues={{ email: '' }}
    >
      {({ isSubmitting }) => (
        <ImageWrapper>
          <Section>
            <Container>
              <StyledPanel hasPadding={false} variant="blueChalk">
                <PanelContent>
                  {!isFinished ? (
                    <Form>
                      <StyledHeading4 as="h2">{title}</StyledHeading4>
                      {!!body && <PortableText blocks={body} />}
                      <Fields>
                        <div>
                          <VisuallyHidden as="label" htmlFor="email">
                            Email
                          </VisuallyHidden>
                          <Field
                            as={Input}
                            id="email"
                            required
                            type="email"
                            autoComplete="email"
                            name="email"
                            placeholder="Email address"
                          />
                        </div>
                      </Fields>

                      <p>
                        By subscribing you agree to our{' '}
                        <SiteLink
                          href="/about/terms-and-conditions"
                          target="_blank"
                        >
                          Terms & Conditions
                        </SiteLink>{' '}
                        and{' '}
                        <SiteLink href="/about/privacy-policy" target="_blank">
                          Privacy & Cookies Policy
                        </SiteLink>
                        .
                      </p>
                      <ButtonContainer>
                        <Button
                          loading={isSubmitting}
                          type="submit"
                          variant="secondary"
                        >
                          Sign Me Up
                        </Button>
                      </ButtonContainer>
                    </Form>
                  ) : (
                    <>
                      <StyledHeading4>Thanks</StyledHeading4>
                      <p>We’ve got it, we’ll be in touch soon.</p>
                      <ButtonContainer>
                        <Link passHref href="/">
                          <Button as="a">Continue Shopping</Button>
                        </Link>
                      </ButtonContainer>
                    </>
                  )}
                </PanelContent>
              </StyledPanel>
            </Container>
            {backgroundImage && (
              <Image
                src={backgroundImage}
                aspectRatio={{ [mobile]: 9 / 10, [tablet]: 1 / 3 }}
                alt=""
                sizes="100vw"
              />
            )}
          </Section>
        </ImageWrapper>
      )}
    </Formik>
  );
}
