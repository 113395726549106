import styled, { css } from 'styled-components';

import { sizeStyles } from 'components/layout/Carousel/styles';
import { CarouselItemProps } from 'components/layout/Carousel/types';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import { QuickBuyWrapper } from 'components/ProductItem/styles';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.white,
  borderRadius: 40,
  [desktop]: {
    borderRadius: 80,
  },
});

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Label = styled.div({
  marginTop: 16,
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: 1.5,
  color: theme.colors.black,
  [tablet]: {
    fontSize: 16,
  },
});

export const Price = styled.div({
  color: theme.colors.darkGreen,
});

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.button<TabLinkProps>`
  ${(props) => props.active && 'display: none;'};
  appearance: none;
  border: 0;
  padding: 0;
  font-size: 16px;
  margin: 0 20px 20px 0;
  background: transparent;
  font-weight: bold;
  color: ${theme.colors.black};
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  cursor: pointer;

  ${tablet} {
    display: unset;
  }

  &:focus,
  &:hover {
    outline: none;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
`;

export const TabsRow = styled.div({
  marginBottom: -20,
});

const hoverStylesWithQuickBuyEnabled = `
  &:hover {
    ${Price} {
      opacity: 0;
    }
    ${QuickBuyWrapper} {
      opacity: 1;
    }
  }`;

const mobileSpecificChildStyles = `
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;

export const StyledProductCarouselItem = styled.div<CarouselItemProps>`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: 0 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;

  ${desktop} {
    ${hoverStylesWithQuickBuyEnabled}
  }

  ${({ inline }) =>
    inline
      ? css`
          ${mobileSpecificChildStyles}
          ${tablet} {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        `
      : css`
          ${mobileSpecificChildStyles}
          ${tablet} {
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        `}

  ${({ size = 'normal' }) => sizeStyles[size]}
`;
