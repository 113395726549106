import React from 'react';

import { PanelWrapper } from './styles';
import { IPanelProps } from './types';

const Panel = ({
  className,
  hasBorder = false,
  hasPadding = true,
  variant,
  id,
  children,
}: IPanelProps) => (
  <PanelWrapper
    className={className}
    id={id}
    variant={variant}
    hasPadding={hasPadding}
    hasBorder={hasBorder}
    data-testid="panel"
  >
    {children}
  </PanelWrapper>
);

export default Panel;
