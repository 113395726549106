import React, { useState, useEffect, useCallback } from 'react';

import Button from 'components/Button';
import AddToNotification from 'components/product/AddToNotification';
import ProductGrid from 'components/ProductGrid';
import { useProductSetAddToBag } from 'pages/explore/hooks';
import { RichTitleWrapper } from 'pages/explore/styles';
import { mobile, tablet } from 'utils/media';
import { PortableText } from 'utils/sanity';

import { Wrapper, TextWrapper, ButtonContainer } from './styles';
import { IProductsSetProps } from './types';

const ProductsSet = ({
  allowBuyAll,
  products,
  text,
  richTitle,
}: IProductsSetProps) => {
  const { addToBag, addedToBag } = useProductSetAddToBag();

  const [showBuyAllBtn, setShowBuyAllBtn] = useState(allowBuyAll);

  useEffect(() => {
    if (allowBuyAll) {
      const allProductsInStock = products.reduce(
        (acc, { isOnStock }) => (acc ? !!isOnStock : false),
        true,
      );

      setShowBuyAllBtn(allProductsInStock);
    }
  }, [allowBuyAll, products]);

  const onAddToBag = useCallback(() => {
    if (addToBag) {
      addToBag(products);
    }
  }, [addToBag, products]);

  return (
    <>
      <Wrapper>
        {richTitle ? (
          <RichTitleWrapper>
            <PortableText blocks={richTitle} />
          </RichTitleWrapper>
        ) : null}
        <ProductGrid
          skeletonOutOfStockProducts
          products={products}
          isQuickBuyEnabled
          columns={{
            [mobile]: 2,
            [tablet]: 4,
          }}
        />

        {text && (
          <TextWrapper>
            <PortableText blocks={text} />
          </TextWrapper>
        )}

        {showBuyAllBtn && addToBag && (
          <ButtonContainer>
            <Button onClick={onAddToBag}>Buy the Look</Button>
          </ButtonContainer>
        )}
      </Wrapper>
      {addedToBag ? (
        <AddToNotification
          isOpen
          productDetails={addedToBag.items}
          key={addedToBag.updatedAt.toString()}
        />
      ) : null}
    </>
  );
};

export default ProductsSet;
